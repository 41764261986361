import React from "react";


const Logout = () => {
    return (
        <div>
            <b>You are not logged in</b>
            <a href={process.env.REACT_APP_COGNITO_LOGIN_URL}>Logout</a>
            </div>
    )
};

export default Logout;