import React, { useState, useEffect } from 'react';
import axios from "axios";

const Home = () => {
    const [data, setData] = useState('');
    const idToken = sessionStorage.getItem('id_token');
    const sendRequest = async () => {
        axios.get('https://api.chatgmx.com', {
                headers: {
                    'Authorization': idToken
                }
            }
        ).then(function (response) {
            setData(JSON.stringify(response?.data?.message));
        });
    };
    useEffect(() => {
        sendRequest();
    });
    return (
        <div>Home page
            <button type="button" onClick={sendRequest} >Test API</button><br/><br/>
            <div> {data} </div>
        </div>
    )
};

export default Home;