import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import { useNavigate } from 'react-router-dom';
import Redirect from './pages/Redirect';
import Logout from './pages/Logout';
import StripeElement from './pages/StripeElement';

function App() {
  const navigate = useNavigate();
  return (
    <>
      <Routes>
        <Route path="/" element={<Login/>}></Route>
        <Route path="/login" element={<Login/>}></Route>
        <Route path="/home" element={<Redirect/>}></Route>
        <Route path="/test" element={<Home/>}></Route>
        <Route path="/logout" element={<Logout/>}></Route>
        {/* <Route path="/payment" element={<StripeElement/>}></Route> */}
      </Routes>
    </>
  );
}

export default App;
