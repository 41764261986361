import React from "react";


const Login = () => {
    return (
        <div>
            <b>You are not logged in</b>
            <a href={process.env.REACT_APP_COGNITO_LOGIN_URL}>Login</a>
        </div>
    )
};

export default Login;