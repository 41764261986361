import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const Redirect = ()=>{
    const location = useLocation();
    const navigate = useNavigate();
    const urlSearchParams = new URLSearchParams(location.hash.substring(1));
    const idToken = urlSearchParams.get('id_token') || '';
    sessionStorage.setItem('id_token', idToken);
    useEffect(() => {
        navigate('/test');
    });
    // fetch('https://xxy8rs235l.execute-api.us-east-1.amazonaws.com/Stage/',{
    //       method: 'GET',
    //       headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': access_token,
    //         'Access-Control-Allow-Origin':'*'
    //       }
    //     }).then((res)=>{console.log(`res ${JSON.stringify(res)}`)});
    return(
        <div/>
    )
}

export default Redirect;